import { useAuthStore } from '~/store/auth.js'

/**
 * Composable that acts as an API client.
 * Provides $fetch functions for common operations over a data model.
 * Always expose the following functions for consistency:
 *
 * This (and other such composables) can be used in setup functions and other places
 * in the Nuxt lifecycle for fetching data.
 *
 * Example would be to use the exposed `index` fetcher with `useFetch` or `useAsyncData` like so:
 * ```javascript
 * import useLocations from '~/composables/useLocations'
 * const { index, create } = useLocations()
 *
 * const {
 *   data: locations,
 *   pending: isLoading,
 *   refresh,
 * } = await useAsyncData('locations', () => index())
 * ```
 * The benefit here is that if multiple pages need the same data, by using the `useAsyncData` composable
 * in all those pages in the same way (with the same key and fetcher),
 * data fetching would happen on server and client-side seamlessly and requests will be deduplicated.
 */
export default function () {
  const { $pinia } = useNuxtApp()
  const config = useRuntimeConfig()
  const store = useAuthStore($pinia)

  const index = (pageNumber) =>
    $fetch(
      `${config.public.apiBaseUrl}/organizations/${store.user.org_id}/locations`,
      {
        key: 'locations',
        params: {
          page: pageNumber,
        },
      },
    )

  const getNavLocations = () =>
    $fetch(
      `${config.public.apiBaseUrl}/organizations/${store.user.org_id}/locations/display/all`,
      {},
    )

  const create = (data) =>
    $fetch(
      `${config.public.apiBaseUrl}/organizations/${store.user.org_id}/locations`,
      {
        method: 'POST',

        body: data,
      },
    )

  const update = (locationId, data) =>
    $fetch(
      `${config.public.apiBaseUrl}/organizations/${store.user.org_id}/locations/${locationId}`,
      {
        method: 'PATCH',

        body: data,
      },
    )

  const updateStatus = (data) =>
    $fetch(
      `${config.public.apiBaseUrl}/organizations/${store.user.org_id}/locations/display`,
      {
        method: 'POST',

        body: data,
      },
    )

  const deleteLocation = (locationId) =>
    $fetch(
      `${config.public.apiBaseUrl}/organizations/${store.user.org_id}/locations/${locationId}`,
      {
        method: 'DELETE',
      },
    )

  const getAllLocations = (id) =>
    $fetch(
      `${config.public.apiBaseUrl}/organizations/${store.user.org_id}/locations/all`,
      {
        key: 'locations',
        params: {
          memberId: id
        },
      },
    )

  return {
    index,
    create,
    update,
    deleteLocation,
    getAllLocations,
    updateStatus,
    getNavLocations,
  }
}
