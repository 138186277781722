import { User } from '@auth0/auth0-spa-js'
import { AppConfig } from '@nuxt/schema'

export function prepareLayoutForLocation(user: User, appConfig: AppConfig) {
  // Only show the authenticated location's board
  if (appConfig.tairo.collapse?.navigation) {
    appConfig.tairo.collapse.navigation.items =
      appConfig.tairo?.collapse.navigation.items.filter(
        (item) =>
          item.name !== 'Locations' && item.name !== 'Insurance Companies',
      )
  }
}

export function addNotificationsInToolbar(appConfig: AppConfig) {
  if (appConfig.tairo.collapse?.toolbar?.tools) {
    appConfig.tairo.collapse.toolbar.tools.splice(1, 0, {
      component: 'DemoToolbarNotifications',
    })
  }
}
