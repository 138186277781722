export const showToastSuccess = (message) => {
  const { show: notify, clearAll } = useToaster()
  clearAll()

  notify({
    title: 'Success',
    message: message,
    color: 'success',
    icon: 'lucide:check',
    closable: true,
  })
}

export const showToastError = (message) => {
  const { show: notify, clearAll } = useToaster()
  clearAll()

  notify({
    title: 'Error',
    message: message,
    color: 'danger',
    icon: 'lucide:alert-octagon',
    closable: true,
  })
}
