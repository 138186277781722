import { ofetch } from 'ofetch'

import { useAuthStore } from '~/store/auth.js'

/**This plugin checks if the API returns a 402 status,
 * indicating that the user does not have an active subscription.
 * If necessary, create another plugin do not change/edit this. **/
export default defineNuxtPlugin((_nuxtApp) => {
  const { $pinia } = useNuxtApp()
  const store = useAuthStore($pinia)

  globalThis.$fetch = ofetch.create({
    onRequest({ options }) {
      // Only add the Authorization header if the token exists in the store
      const token = store.token
      if (token) {
        options.headers = options.headers || {}
        options.headers.Authorization = `Bearer ${token}`
      }
    },

    onResponseError({ response }) {
      switch (response.status) {
        case 402:
          navigateTo('/payment')
          break
        case 429:
          showToastError(
            'Server too busy. Please refresh the page and try again.',
          )
          break
        case 403:
          showToastError(
            'You do not have permission to access this resource. Please ensure you have the proper credentials.',
          )
          navigateTo('/dashboard')
          break
        case 404:
          navigateTo('/error-404')
          break
        default:
          console.error(response.status)
          break
      }
    },
  })
})
